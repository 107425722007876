<template>
  <div class="container">
    {{ $route.meta.title }}
  </div>
</template>
<script>
import { scanQRCode } from '@/common/wx'
import PageMixin from '@/mixins/page'

export default {
  name: 'CampusQrcode',
  mixins: [PageMixin],
  data() {
    return {

    }
  },
  created() {
    this.wechatConfigSuccessCallback()
  },
  methods: {
    wechatConfigSuccessCallback() {
      scanQRCode(1, ['qrCode']).then(res => {
        console.log(res)
        if (res.resultStr) {
          this.$api.tools_scan({ scan_code: res.resultStr })
            .then(res => {
              if (res.data.code && res.data.code === 'success') {
                this.$dialog.confirm({
                // title: '标题',
                  confirmButtonText:'继续',
                  message: '扫码签到成功'
                })
                  .then(() => {
                  // on confirm
                    this.wechatConfigSuccessCallback()
                  })
                  .catch(() => {
                  // on cancel
                  this.$router.back()
                  })
              } else {
                this.$dialog.confirm({
                // title: '标题',
                  confirmButtonText:'继续',
                  message: '扫码签到失败'
                })
                  .then(() => {
                  // on confirm
                    this.wechatConfigSuccessCallback()
                  })
                  .catch(() => {
                  // on cancel
                  this.$router.back()
                  })
              }
            })
            .catch(err => {
              console.error(err)
              this.$dialog.confirm({
                // title: '标题',
                confirmButtonText:'继续',
                message: '扫码签到失败'
              })
                .then(() => {
                  // on confirm
                  this.wechatConfigSuccessCallback()
                })
                .catch(() => {
                  // on cancel
                  this.$router.back()
                })
            })
        }
      })
      .catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>

